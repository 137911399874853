import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { TabsPageModule } from './tabs/tabs.module';

const routes: Routes = [
  
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './public/login/login.module#LoginPageModule' },
  { path: 'tabs/tab1', canActivate: [AuthGuardService], loadChildren: './tabs/tabs.module#TabsPageModule'},
  { path: 'register', loadChildren: './public/register/register.module#RegisterPageModule' },
  { path: 'termsofuse', loadChildren: './public/termsofuse/termsofuse.module#TermsofusePageModule' },
  
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }), TabsPageModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}


