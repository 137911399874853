import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceService } from '../services/service.service';
import { AlertController, Platform, LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { User_pk } from '../services/authentication.service';
import { Router, RoutesRecognized, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-tab2',
  templateUrl: 'tab2.page.html',
  styleUrls: ['tab2.page.scss']
})
export class Tab2Page implements OnInit {
  data;
  page=null;
  next: any;
  data3: any;
  data2: string;
  deleteD: any;
  user_id: string = '999999';
  kolicina=
  {
    kolicina:''
  };
  search='';
  subscription: any;
  previousUrl: string;
  currentUrl: string;
  constructor(private http: HttpClient, private router: Router, private serviceServ: ServiceService,
  private alertController: AlertController, private storage: Storage, private plt : Platform, 
  public loadingController: LoadingController) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {        
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        if(this.previousUrl=="/tabs/tab2/imam"){this.page=null;this.ImamPloce();}
      };
    });
  } 
  
  async ngOnInit(){
    await this.storage.get(User_pk).then(async val=>{this.user_id= await val;});
    this.page=null;
    this.ImamPloce();
  }
  async ImamPloce(){
    await this.storage.get(User_pk).then(async val=>{this.user_id= await val;});
    this.page++;
    this.serviceServ.ImamData(this.page,this.user_id,this.search).subscribe(res=>{
    this.data2=JSON.stringify(res);
    this.data3=(JSON.parse(this.data2).results);
    this.data=this.data3;
    this.next=(JSON.parse(this.data2).next);});
  }
  loadData(event) {
    this.loading();
    setTimeout(() => {
    if(this.next!=null){
      this.page++;
      this.serviceServ.ImamData(this.page, this.user_id,this.search).subscribe( res=>{
      this.data2=JSON.stringify(res);
      this.data3=(JSON.parse(this.data2).results);
      this.data=this.data.concat(this.data3);
      this.next=(JSON.parse(this.data2).next);
    });
    }
    if(this.next==null) {
      event.target.disabled = false
    }
    }, 1000);
    }
  newboard(){
    this.router.navigate(["tabs/tab2/imam"]);
  }
  delete(data){
    if(data.kolicina>1){
     console.log(data.kolicina)
     let test = data.kolicina-1;
     this.kolicina.kolicina=test.toString();
     this.serviceServ.ImamKolicina(data.id, this.kolicina).subscribe(res=>{console.log(res)});
     this.page=null;
     this.ImamPloce();
    }
    else
    {
    this.deleteD=data.id;
    this.AskToConfirmDelete();
    }
  } 
  async AskToConfirmDelete() 
  {
    const alert = await this.alertController.create({
      header: '',
      message: 'Da li sigurno zelite da izbrisete ovaj modul?',
      buttons: [
        {
          text: 'Ne',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Da',
          handler: () => {
            this.serviceServ.ImamDelete(this.deleteD).subscribe(res=>{console.log(res);});
            setTimeout( () => {
                this.page=null;
                this.ImamPloce();
            }, 1000);
          }
        }
      ]
    });
    await alert.present();
  }
OpenDetails(data){
    this.serviceServ.Detalji = data;
    this.router.navigate(['tabs/tab2/imamdetails']);
  }
  PrvaSt($event){
    this.data=[];
    this.page=null;
    this.search.toUpperCase();
    this.ImamPloce();
  }
 async loading(){
  const loading = await this.loadingController.create({
    message: 'Ucitavam...',
    duration: 500
  });
  await loading.present(); 
}   
}
