import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Platform, AlertController, LoadingController } from '@ionic/angular';
import { Storage} from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

export const TOKEN_KEY='token';
export const User_username='1';
export const User_password='2';
export const User_first_name='3';
export const User_last_name='4';
export const User_email='5';
export const User_dateofbirth='6';
export const User_phone='7';
export const User_pk='8';
export const User_imeservisa='9';
export const User_adresa='10';
export const User_grad='11';
export const User_servisugaranciji1='12';
export const User_servisugaranciji2='13';
export const User_servisugaranciji3='14';
export const User_servisugaranciji4='15';
export const User_servisugaranciji5='16';
export const User_servisugaranciji6='17';
export const User_servisugaranciji7='18';
export const User_servisugaranciji8='19';
export const User_servisugaranciji9='20';
export const User_servisugaranciji10='21';
export const User_notification='yes';
export const User_group='Majstor';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  
  url= 'https://livada.pythonanywhere.com/api/';
  Token=null;
  store1;
  store2;
  primar;
  username;
  
  authenticationState = new BehaviorSubject(false);

  constructor(private plt: Platform,private http: HttpClient, private storage: Storage,
     private alertController: AlertController, public loadingController: LoadingController) {
    this.plt.ready().then(()=>{
        this.checkToken();
    });
   }
  async loginUser(LogUser)
  { 
    this.http.post(this.url+'api-token-auth/', LogUser)
    .subscribe(
     async res =>
      { 
        this.store1=JSON.stringify(res),
        this.store2=JSON.parse(this.store1).token;
        JSON.stringify(this.store2);
        this.Token=this.store2;
        await this.storage.set(TOKEN_KEY, this.store2) 
        await this.storage.set(User_username, LogUser.username);
        this.username=LogUser.username;
        await this.storage.set(User_password, LogUser.password);
        this.userrolle(LogUser.username);
        this.loading();
        setTimeout( () => {
          return this.authenticationState.next(true)
        }, 2000);
        
      },
      error => 
        {
          if(error.statusText=="Bad Request"){
            let msg = "Pogresno korisnicko ime ili lozinka."
            this.alert(msg);
          }
          else{
            let msg = "Molimo Vas proverite internet konekciju."
            this.alert(msg);
          }
        });
        
      }
  
  logout()
  {  
    return this.storage.remove(TOKEN_KEY).then (() =>
    {
      this.storage.clear();
      this.storage.set(User_notification,'yes');
      this.authenticationState.next(false);
    });
  }

  isAuthenticated()
  {
    return this.authenticationState.value;
  }

  checkToken() 
  {
    return this.storage.get(TOKEN_KEY).then (async val =>
    {
      if (val!='token'&&val!=null)
      { 
        this.Token=val;
        this.authenticationState.next(true);
      }
      else 
      {
        this.authenticationState.next(false);
      }
    });
  }
alert(msg){
  let alert = this.alertController.create({
    message: msg ,
    header: 'Greska!',
    buttons: ['OK']
    })
    alert.then(alert => alert.present());
}
  userrolle(username){
  this.http.get(this.url+'users/?search='+username).subscribe(async res=>{
    let a =JSON.stringify(res);
    let b =JSON.parse(a);
    this.primar= b.results[0].pk;
    await this.storage.set(User_pk, this.primar);
    await this.storage.set(User_first_name, b.results[0].first_name)
    await this.storage.set(User_last_name, b.results[0].last_name)
    await this.storage.set(User_email, b.results[0].email)
    let group=(b.results[0].groups);
    let c=JSON.stringify(group);
    if(c=="[1]"){console.log("Administrator");
      await this.storage.set(User_group,"Administrator");
      }
    if(c=="[2]"){console.log("Serviser");
      await this.storage.set(User_group,"Serviser");  
      }
      if(c=="[3]"){console.log("Trgovac");
      await this.storage.set(User_group,"Trgovac");  
      }
    if(this.primar!=undefined){
      this.kor();
    }
   });
}
kor(){
  this.http.get(this.url+'korisnik/'+this.primar+'/').subscribe(async res=>{
  let a =JSON.stringify(res);
  let b =JSON.parse(a);
  await this.storage.set(User_grad,b.grad);
  await this.storage.set(User_dateofbirth,b.datum_rodjenja);
  await this.storage.set(User_phone,b.telefon);
  await this.storage.set(User_adresa,b.adresa);
  await this.storage.set(User_imeservisa,b.ime_servisa);
  await this.storage.set(User_servisugaranciji1,b.servis_u_garanciji1);
  await this.storage.set(User_servisugaranciji2,b.servis_u_garanciji2);
  await this.storage.set(User_servisugaranciji3,b.servis_u_garanciji3);
  await this.storage.set(User_servisugaranciji4,b.servis_u_garanciji4);
  await this.storage.set(User_servisugaranciji5,b.servis_u_garanciji5);
  await this.storage.set(User_servisugaranciji6,b.servis_u_garanciji6);
  await this.storage.set(User_servisugaranciji7,b.servis_u_garanciji7);
  await this.storage.set(User_servisugaranciji8,b.servis_u_garanciji8);
  await this.storage.set(User_servisugaranciji9,b.servis_u_garanciji9);
  await this.storage.set(User_servisugaranciji10,b.servis_u_garanciji10);
  if(b.notification===true){
    await this.storage.set(User_notification,'yes');
  }
  else{
    await this.storage.set(User_notification,'no');
  }
  });}
  async loading(){
    const loading = await this.loadingController.create({
      message: 'Molimo sacekajte...',
      duration: 2000
    });
    await loading.present();
  }
}