import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TabsPage } from './tabs.page';

const routes: Routes = [
  {
    path: 'tabs',
    component: TabsPage,
    children: [
      {
        path: 'tab1',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../tab1/tab1.module').then(m => m.Tab1PageModule)
          }
        ]
      },
      {
        path: 'tab1/trazim',
        children: [
          {
            path: '',
            loadChildren: '../pages/trazim/trazim.module#TrazimPageModule'
          }
        ]
      },
      {
        path: 'tab1/trazimdetails',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../pages/trazimdetails/trazimdetails.module').then(m => m.TrazimdetailsPageModule)
          }
        ]
      },
      {
        path: 'tab2',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../tab2/tab2.module').then(m => m.Tab2PageModule)
          }
        ]
      },
      {
        path: 'tab2/imam',
        children: [
          {
            path: '',
            loadChildren: '../pages/imam/imam.module#ImamPageModule'
          }
        ]
      },
      {
        path: 'tab2/imamdetails',
        children: [
          {
            path: '',
            loadChildren: '../pages/imamdetails/imamdetails.module#ImamdetailsPageModule'
          }
        ]
      },
      {
        path: 'tab3',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../tab3/tab3.module').then(m => m.Tab3PageModule)
          }
        ]
      },
      {
        path: 'tab3/trebajudetails',
        children: [
          {
            path: '',
            loadChildren: '../pages/trebajudetails/trebajudetails.module#TrebajudetailsPageModule'
          }
        ]
      },
      {
        path: 'tab4',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../tab4/tab4.module').then(m => m.Tab4PageModule)
          }
        ]
      },
      {
        path: 'tab4/svedetails',
        children: [
          {
            path: '',
            loadChildren: '../pages/svedetails/svedetails.module#SvedetailsPageModule'
          }
        ]
      },
      {
        path: 'tab5',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../tab5/tab5.module').then(m => m.Tab5PageModule)
          }
        ]
      },
      {
        path: 'tab6',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../tab6/tab6.module').then(m => m.Tab6PageModule)
          }
        ]
      },
      {
        path: 'tab7',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../tab7/tab7.module').then(m => m.Tab7PageModule)
          }
        ]
      },
      {
        path: 'tab8',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../tab8/tab8.module').then(m => m.Tab8PageModule)
          }
        ]
      },
      {
        path: '',
        redirectTo: '/tabs/tab1',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '',
    redirectTo: '/tabs/tab1',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TabsPageRoutingModule {}
