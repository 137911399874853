import { Component } from '@angular/core';
import { AuthenticationService, User_notification, User_pk } from './services/authentication.service';
import { Platform, AlertController, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { tap } from 'rxjs/operators';
import { ServiceService } from './services/service.service';
import { Tab1Page } from './tab1/tab1.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthenticationService,
    private router: Router,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    this.authService.authenticationState.subscribe(state => {
      if (state) { 
        this.router.navigate(['tabs/tab1']); 
      }
      else {
        this.router.navigate(['login']);
      }
    });
  }
}
