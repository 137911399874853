import { Component, OnInit, ViewChild } from '@angular/core';
import { Platform, AlertController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { ServiceService } from '../services/service.service';
import { Storage } from '@ionic/storage';
import { LoadingController } from '@ionic/angular';
import { User_pk } from '../services/authentication.service';



@Component({
  selector: 'app-tab1',
  templateUrl: 'tab1.page.html',
  styleUrls: ['tab1.page.scss']
})
export class Tab1Page implements OnInit {
  data;
  next: any;
  user_id = "1";
  deleteD: any;
  page = null;
  search = "";
  mogucepoklopanje;
  subscription;
  currentUrl: string;
  previousUrl: string;

  constructor(private http: HttpClient, private plt: Platform, private router: Router,
    private serviceServ: ServiceService, private alertController: AlertController,
    private storage: Storage, private loadingController: LoadingController) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        if (this.previousUrl = "/tabs/tab1/trazim") { this.page = null; this.TrazimPloce(); }
      };
    });
  }
  async ngOnInit() {
    await this.storage.get(User_pk).then(val => { this.user_id = val; })
    this.page = null;
    this.TrazimPloce();
  }
  TrazimPloce() {
    this.page++;
    this.serviceServ.TrazimData(this.page, this.user_id, this.search).subscribe(res => {
      let data2 = JSON.stringify(res);
      let data3 = (JSON.parse(data2).results);
      this.data = data3;
      this.next = (JSON.parse(data2).next);
    });
  }
  loadData(event) {
    this.loading();
    setTimeout(() => {
      if (this.next != null) {
        this.page++;
        this.serviceServ.TrazimData(this.page, this.user_id, this.search).subscribe(res => {
          let data2 = JSON.stringify(res);
          let data3 = (JSON.parse(data2).results);
          this.data = this.data.concat(data3);
          this.next = (JSON.parse(data2).next);
        })
        event.target.complete();
      }
      if (this.next == null) {
        event.target.disabled = true
      }
      if (event.target.disabled = true) { event.target.disabled = false }
    }, 1000);
  }
  newboard() {
    this.router.navigate(["tabs/tab1/trazim"]);
  }
  delete(data) {
    this.AskToConfirmDelete();
    this.deleteD = data.id;
  }
  OpenDetails(data) {
    this.serviceServ.Detalji = data;
    this.router.navigate(['tabs/tab1/trazimdetails']);
  }
  async AskToConfirmDelete() {
    const alert = await this.alertController.create({
      header: '',
      message: 'Da li sigurno zelite da izbrisete ovaj modul?',
      buttons: [
        {
          text: 'Ne',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Da',
          handler: () => {
            this.serviceServ.TrazimDelete(this.deleteD).subscribe(res => res);
            setTimeout(() => {
              this.data = undefined;
              this.page = null;
              this.TrazimPloce();
            }, 1000);
          }
        }
      ]
    });
    await alert.present();
  }
  PrvaSt($event) {
    this.data = [];
    this.page = null;
    this.search.toUpperCase();
    this.TrazimPloce();
  }
  async loading() {
    const loading = await this.loadingController.create({
      message: 'Ucitavam...',
      duration: 700
    });
    await loading.present();
  }
}