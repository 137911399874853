import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
//import { AuthenticationService } from '../services/authentication.service';
@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  Detalji;
  DetaljiPoklapanja;
  url="https://livada.pythonanywhere.com/api/";
  
  constructor(private http: HttpClient, ) { }

  TrazimData(page,userid,search): Observable<any> {
    return this.http.get(this.url+'trazim/?page='+page+'&user='+userid+'&search='+search)
    .pipe(results => results);
   }
   TrazimDelete(id): Observable<any>{
     return this.http.delete(this.url+'trazim/'+id+'/' )
     .pipe(results => results);
   }
   ImamData(page,userid,search): Observable<any> {
    return this.http.get(this.url+'imam/?page='+page+'&user='+userid+'&search='+search)
    .pipe(results => results);
   }
   ImamDelete(id): Observable<any> {
    return this.http.delete(this.url+'imam/'+id+'/' )
    .pipe(results => results);
   }
   ImamKolicina(id,kolicina): Observable<any>{
     return this.http.put(this.url+'imam/'+id+'/', kolicina )
     .pipe(results => results);
   }
   SavetiData(page,search): Observable<any> {
    return this.http.get(this.url+'saveti/?page='+page+'&search='+search)
    .pipe(results => results);
   }
   SvePloce(page, search): Observable<any> {
     return this.http.get(this.url+'sve/?page='+page+'&search='+search )
     .pipe(results => results);
   }
   RegisterNewUser(newUser):Observable<any> {
    return this.http.post(this.url+'users/', newUser)
  }
  UserData(user):Observable<any>{
    return this.http.get(this.url+'users/'+user+'/');
  }
  RegisterKorisnikData(pk, newKor):Observable<any> {
    return this.http.put(this.url+'korisnik/'+pk+"/" , newKor)
  }
  KorisnikFirebaseToken(pk, token):Observable<any> {
    return this.http.patch(this.url+'korisnici/'+pk+"/" , token);
  }
  GetKorisnikData(pk):Observable<any> {
    return this.http.get(this.url+'korisnik/'+pk+'/')
    .pipe(results => results);
  }
  PodudaranjeDodaj(pk, data):Observable<any>{
    return this.http.patch(this.url+'trazim/'+pk+'/', data )
    .pipe(results => results);
  }
  PodudaranjeProveri(pk):Observable<any>{
    return this.http.get(this.url+'trazim/'+pk+'/')
    .pipe(results => results);
  }
  SvePloceDetails(idploce):Observable<any>{
    return this.http.get(this.url+'sve/'+idploce+'/')
    .pipe(results => results);
  }
  ServisiGarancija(page,search):Observable<any>{
    return this.http.get(this.url+'korisnik/?page='+page+'&search='+search)
    .pipe(results => results);
  }
  //public getHttpOptions() {
  //  return {
  //  headers: new HttpHeaders
 //   ({
  //  'Content-Type': 'application/json', 
  //   Authorization: 'Token ' + this.authServ.Token,
   // })
   // };
  //  }
}
//private authServ : AuthenticationService